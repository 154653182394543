<template>
	<div class="content">
		<div class="language" @click="clickLanguage">
			<p>{{ $t('lang.language') }}</p>
		</div>
		<div class="share">
			<div class="seize-seat"></div>
			<div class="login-code">
				<div class="welcomelogo">
					<img src="@/assets/img/login/welcome.png" alt="">
					<p>{{ $t('lang.SignUpTitle') }}</p>
				</div>
				<div class="code-phone">
					<img src="@/assets/img/login/login-vector.png" alt="">
					<input type="text" :placeholder="$t('lang.email')" v-model="email" />
				</div>
				<div class="code-code">
					<img src="@/assets/img/login/login-verify.png" alt="">
					<input type="text" :placeholder="$t('lang.email_code')" v-model="code" />
					<div class="get-code" v-if="disabled" @click="getCode">{{ $t('lang.getCode') }}</div>
					<div class="get-code" v-else>{{ second }}s</div>
				</div>
				<div class="code-pwd">
					<img src="@/assets/img/login/login-lock.png" alt="">
					<input :type="inputPwd" maxlength="10" :placeholder="$t('lang.pwd')" v-model="pwd" />
					<van-icon name="closed-eye" color="#000" size="0.32rem" v-if="eyePwdIs == true" @click="clickEyeIs" />
					<van-icon name="eye-o" color="#000" size="0.32rem" v-if="eyePwdIs == false" @click="clickEye" />
				</div>
				<div class="code-pwd">
					<img src="@/assets/img/login/login-lock.png" alt="">
					<input :type="inputPwdIn" maxlength="10" :placeholder="$t('lang.pwdIn')" v-model="pwdIn" />
					<van-icon name="closed-eye" color="#000" size="0.32rem" v-if="eyePwdInIs == true" @click="clickEyeInIs" />
					<van-icon name="eye-o" color="#000" size="0.32rem" v-if="eyePwdInIs == false" @click="clickEyeIn" />
				</div>
				<div class="code-invitation">
					<img src="@/assets/img/login/Invitation-code.png" alt="">
					<input type="text" :placeholder="$t('lang.recomId')" v-model="recomId" />
				</div>
				<button class="code-but" @click="loginBut">{{ $t('lang.loginBut') }}</button>
			</div>
		</div>
		<div style="text-align: center;padding-top: 0.3rem;font-size: 0.24rem;color: #000;padding-bottom: 0.2rem;"
			@click="goDownload">
			{{ $t('lang.download') }} Unilive</div>
		<div class="card-checkbox">
			<div class="card-paction">{{ $t('lang.check') }}
				<span @click="goPrivacyPolicy">{{ $t('lang.privacyPolicy') }}</span>
				{{ $t('lang.and') }}<span @click="goUserAgree">{{ $t('lang.userAgree') }}</span>
			</div>
		</div>
		<!-- 顶部弹出 -->
		<van-popup v-model:show="showTop" round position="bottom" closeable close-icon="close" :style="{ height: '70%' }">
			<div class="languageBox">
				<div style="padding-left: 5%; padding-bottom: 0.4rem;">{{ $t('lang.chooselanguage') }}</div>
				<div v-for="(item, index) in languageItem" :key="item" @click="selectLanguage(index)">
					<div class="languageItem">{{ item }}</div>
				</div>
			</div>
		</van-popup>
		<!-- 获取验证码滑块验证 -->
		<div class="touch">
			<Verify ref="verifyCode" :captcha-type="'blockPuzzle'" :img-size="{ width: '300px', height: '150px' }"
				@success="getCodeSuccess" />
		</div>
		<!-- 注册滑块验证 -->
		<div class="touch">
			<Verify ref="verifyLogin" :captcha-type="'blockPuzzle'" :img-size="{ width: '300px', height: '150px' }"
				@success="loginSuccess" />
		</div>
	</div>
</template>

<script>
import HB from "@/assets/js/bridge";
import { sendCodeH5, loginH5, sendCode, login } from "../../assets/api/login.js";
import { encrypt, decrypt } from "@/assets/js/utils";

import Verify from '../../components/verifition/Verify.vue'

export default {
	components: {
		Verify
	},
	computed: {
		languageItem() {
			return [this.$t("lang.language1"), this.$t("lang.language2"), this.$t("lang.language3"), this.$t("lang.language4"), this.$t("lang.language5"), this.$t("lang.language6"), this.$t("lang.language7")]
		}
	},
	data() {
		return {
			language: 'en_US',
			disabled: true,
			state: true,
			second: 180,
			email: null,
			code: null,
			recomId: null,
			pwd: '',
			pwdIn: null,
			showTop: false,
			eyePwdIs: true,
			eyePwdInIs: true,
			inputPwd: 'password',
			inputPwdIn: 'password'
		}
	},
	created() {

	},
	mounted() {
		console.log(this.$route)
		const hash = window.location.hash;

		// 提取查询参数
		const params = new URLSearchParams(hash.substring(2)); // 去掉 "#/" 部分
		this.recomId = params.get('recomId');
		// url获取参数
		if (params.get('language')) {
			this.language = params.get('language');
			localStorage.setItem('lang', this.language);
			this.$i18n.locale = localStorage.getItem("lang");
		}
		console.log(this.recomId)
		console.log(this.language)
	},
	methods: {
		clickEyeIs() {
			this.eyePwdIs = false;
			this.inputPwd = 'text';
		},
		clickEyeInIs() {
			this.eyePwdInIs = false;
			this.inputPwdIn = 'text'
		},
		clickEye() {
			this.eyePwdIs = true;
			this.inputPwd = 'password';
		},
		clickEyeIn() {
			this.eyePwdInIs = true;
			this.inputPwdIn = 'password';
		},

		// 发送邮箱验证码
		getCodeSuccess(params) {
			sendCode({
				loginName: this.email,
				sendType: 'emailSend',
				type: 'REGISTER_CODE',
				captchaVerification: params.captchaVerification
			}).then((res) => {
				// console.log(res, '发送邮箱验证码');
				if (res.code == 0) {
					this.Toast(res.msg);
					this.disabled = false;
					var interval = setInterval(() => {
						let times = --this.second;
						this.second = times < 10 ? '0' + times : times; //小于10秒补 0
					}, 1000);
					setTimeout(() => {
						clearInterval(interval)
						this.second = 180;
						this.disabled = true;
					}, 180000);
				} else if (res.code == 87069) {
					this.Toast(res.msg);
				} else {
					this.Toast(res.msg);
				}
			})
		},
		loginSuccess(params) {
			login({
				loginName: this.email,
				verCode: this.code,
				// loginPwd: encrypt(this.pwd),
				loginPwd: this.pwd,
				inviteNo: this.recomId,
				loginType: 'emailCodeLogin',
				captchaVerification: params.captchaVerification
			}).then((res) => {
				// console.log(res, '手机号验证码密码注册');
				if (res.code == 0) {
					this.Toast(this.$t('lang.loginToast9'));
					this.$router.push({
						path: '/download'
					})
				} else {
					this.Toast(res.msg)
				}
			})
		},


		// 切换语言
		clickLanguage() {
			this.showTop = true;
		},
		// 选择语言
		selectLanguage(index) {
			this.showTop = false;
			switch (index) {
				case 0:
					this.$i18n.locale = 'zh_CN';
					localStorage.setItem('lang', 'zh_CN');
					break;
				case 1:
					this.$i18n.locale = 'en_US';
					localStorage.setItem('lang', 'en_US');
					break;
				case 2:
					this.$i18n.locale = 'ja_JP';
					localStorage.setItem('lang', 'ja_JP');
					break;
				case 3:
					this.$i18n.locale = 'ko_KR';
					localStorage.setItem('lang', 'ko_KR');
					break;
				case 4:
					this.$i18n.locale = 'ru_RU';
					localStorage.setItem('lang', 'ru_RU');
					break;
				case 5:
					this.$i18n.locale = 'vi_VN';
					localStorage.setItem('lang', 'vi_VN');
					break;
				default:
					this.$i18n.locale = 'th_TH';
					localStorage.setItem('lang', 'th_TH');
			}
		},
		// 关闭弹窗
		close() {
			this.showTop = false;
		},
		// 选中、未选中切换
		checkedBut() {
			this.state = !this.state;
		},
		// 隐私政策
		goPrivacyPolicy() {
			this.$router.push({
				path: '/PrivacyPolicyEn'
			})
		},
		// 用户协议
		goUserAgree() {
			this.$router.push({
				path: '/UserServiceAgreementEn'
			})
		},
		goDownload() {
			this.$router.push({
				path: '/download'
			})
		},

		// 点击获取验证码
		getCode() {
			var pattern = /^[A-Za-z0-9]+([_.][A-Za-z0-9]+)*@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/;
			if (!pattern.test(this.email)) {
				this.Toast('请输入有效的邮箱');
				return false;
			}
			if (this.email == null || this.email == "") {
				this.Toast('请先输入邮箱');
				return false;
			}
			// 显示滑块验证
			this.$refs.verifyCode.show();
			// 调用发送短信验证码
			// this.sendCodeH5();
		},

		// 发送短信验证码
		sendCodeH5() {
			sendCodeH5({
				loginName: this.email,
				sendType: 'emailSend',
				type: 'REGISTER_CODE'
			}).then((res) => {
				// console.log(res, '发送邮箱验证码');
				if (res.code == 0) {
					this.Toast(res.msg);
					this.disabled = false;
					var interval = setInterval(() => {
						let times = --this.second;
						this.second = times < 10 ? '0' + times : times; //小于10秒补 0
					}, 1000);
					setTimeout(() => {
						clearInterval(interval)
						this.second = 180;
						this.disabled = true;
					}, 180000);
				} else if (res.code == 87069) {
					this.Toast(res.message);
				} else {
					this.Toast(res.msg);
				}
			})
		},

		// 手机号验证码密码注册
		loginBut() {
			if (!this.state) {
				this.Toast(this.$t('lang.loginToast1'));
				return false
			}
			var pattern = /^[A-Za-z0-9]+([_.][A-Za-z0-9]+)*@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/;
			if (!pattern.test(this.email)) {
				this.Toast(this.$t('lang.loginToast2'));
				return false;
			}
			if (this.email == null || this.email == "") {
				this.Toast(this.$t('lang.loginToast3'));
				return false;
			}
			if (this.code == null || this.code == "") {
				this.Toast(this.$t('lang.loginToast4'));
				return false;
			}
			if (this.pwd == null || this.pwd == "") {
				this.Toast(this.$t('lang.loginToast5'));
				return false;
			}
			if (this.pwd.length < 8) {
				this.Toast(this.$t('lang.loginToast6'));
				return false;
			}
			if (this.pwdIn == null || this.pwdIn == "") {
				this.Toast(this.$t('lang.loginToast7'));
				return false;
			}
			if (this.pwd != this.pwdIn) {
				this.Toast(this.$t('lang.loginToast8'));
				return false;
			}
			// 显示滑块验证
			this.$refs.verifyLogin.show();
			// 调用注册接口
			this.loginH5();
		},
		// 注册接口
		loginH5() {
			loginH5({
				loginName: this.email,
				verCode: this.code,
				// loginPwd: encrypt(this.pwd),
				loginPwd: this.pwd,
				inviteNo: this.recomId,
				loginType: 'emailCodeLogin'
			}).then((res) => {
				// console.log(res, '手机号验证码密码注册');
				if (res.code == 0) {
					this.Toast(this.$t('lang.loginToast9'));
				} else {
					this.Toast(res.msg)
				}
			})
		},
	}
}
</script>

<style scoped>
/deep/.van-popup__close-icon {
	font-size: 0.5rem;
}

/deep/.van-popup__close-icon--top-right {
	top: 0.6rem;
	right: 0.3rem;
}

.touch {
	touch-action: none;
	touch-action: pan-y;
}

::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	font-size: 0.28rem !important;
	color: #3C3C3C;
	opacity: 0.8;
	font-weight: 500;
}

:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	font-size: 0.28rem !important;
	color: #3C3C3C;
	opacity: 0.8;
	font-weight: 500;
}

::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	font-size: 0.28rem !important;
	color: #3C3C3C;
	opacity: 0.8;
	font-weight: 500;
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	font-size: 0.28rem !important;
	color: #3C3C3C;
	opacity: 0.8;
	font-weight: 500;
}

.welcomelogo {
	font-size: 0.25rem;
	color: #3C3C3C;
}

.welcomelogo p {
	color: #3C3C3C;
	font-size: 0.25rem;
	margin-left: 0.5rem;
}

.welcomelogo img {
	width: 70vw;
	height: 7vh;
	margin-left: 0.5rem;
}

.language {
	position: absolute;
	top: 2%;
	right: 5%;
	display: flex;
	align-items: center;
}

.language img {
	display: block;
	width: 0.56rem;
	height: 0.56rem;
}

.language p {
	font-size: 0.3rem;
	color: #000000;
}

/* 选择语言弹窗 */
.languageBox {
	width: 100%;
	margin: 0 auto;
	padding-top: 1.4rem;
	font-size: 0.4rem;
}

.languageItem {
	height: 1rem;
	/* border-bottom: 1px solid #E5E5E5; */
	font-size: 0.3rem;
	line-height: 1rem;
	padding-left: 5%;
	color: #3C3C3C;
	/* text-align: center; */
}


.content {
	width: 100%;
	min-height: 100%;
	/* background-color: #111118; */
	background: url('../../assets/img/login/register.png') no-repeat center center;
	background-size: cover;
}

.seize-seat {
	width: 100%;
	height: 4rem;
}

.login-code {
	padding-top: 0.4rem;
	background-color: #fff;
	border-radius: 0.5rem;
}

.code-phone {
	width: 6.5rem;
	height: 1rem;
	margin: 0 auto;
	margin-top: 0.2rem;
	border-radius: 1rem;
	background-color: #F2F5F1;
	display: flex;
	align-items: center;
}

.code-phone img {
	width: 0.5rem;
	height: 0.36rem;
	margin-left: 0.3rem;
}

.code-phone input {
	width: 100%;
	font-size: 0.28rem !important;
	line-height: 0.7rem;
	letter-spacing: 1px;
	color: #3C3C3C;
	margin-left: 0.3rem;
	caret-color: #3C3C3C;
}

.code-code {
	width: 6.5rem;
	height: 1rem;
	margin: 0 auto;
	border-radius: 1rem;
	margin-top: 0.35rem;
	background-color: #F2F5F1;
	display: flex;
	align-items: center;
}

.code-code img {
	width: 0.5rem;
	height: 0.36rem;
	margin-left: 0.34rem;
}

.code-code input {
	width: 70%;
	font-size: 0.28rem !important;
	line-height: 0.7rem;
	letter-spacing: 1px;
	color: #3C3C3C;
	margin-left: 0.3rem;
	caret-color: #3C3C3C;
}

.code-code .get-code {
	width: 2.4rem;
	height: 0.5rem;
	margin-right: 0.3rem;
	border-radius: 4px;
	font-size: 0.24rem;
	line-height: 0.5rem;
	color: #000;
	font-weight: 400;
	text-align: right;
}

.code-pwd {
	width: 6.5rem;
	height: 1rem;
	margin: 0 auto;
	border-radius: 1rem;
	margin-top: 0.35rem;
	background-color: #F2F5F1;
	display: flex;
	align-items: center;
}

.code-pwd img {
	width: 0.5rem;
	height: 0.36rem;
	margin-left: 0.36rem;
}

.code-pwd input {
	width: 100%;
	font-size: 0.28rem !important;
	line-height: 0.7rem;
	letter-spacing: 1px;
	color: #3C3C3C;
	margin-left: 0.3rem;
	caret-color: #3C3C3C;
}

.code-pwd .van-icon {
	margin-right: 0.3rem;
}

.code-invitation {
	width: 6.5rem;
	height: 1rem;
	margin: 0 auto;
	border-radius: 1rem;
	margin-top: 0.35rem;
	background-color: #F2F5F1;
	display: flex;
	align-items: center;
}

.code-invitation img {
	width: 0.5rem;
	height: 0.36rem;
	margin-left: 0.34rem;
}

.code-invitation input {
	width: 100%;
	font-size: 0.28rem !important;
	line-height: 0.7rem;
	letter-spacing: 1px;
	color: #000000;
	margin-left: 0.3rem;
	caret-color: #000000;
}

.code-but {
	display: block;
	width: 6.5rem;
	height: 1rem;
	margin: 0 auto;
	margin-top: 0.63rem;
	background: #99FE46;
	border-radius: 0.5rem;
	font-size: 0.30rem !important;
	color: #000;
	font-weight: 600;
}

/* 注册成功 */
.succeed-bg {
	width: 6.5rem;
	height: 6rem;
	background-image: url(../../assets/img/login/succeed-bg.png);
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.succeed-bg img {
	display: block;
	width: 0.8rem;
	height: 0.8rem;
	margin: 0 auto;
	padding-top: 1rem;
	padding-bottom: 0.2rem;
}

.succeed-bg p {
	font-size: 0.3rem;
	color: #000000;
	text-align: center;
}

/* 复选框 */
.card-checkbox {
	width: 86%;
	margin: 0 auto;
	overflow: hidden;
	margin-top: 0.1rem;
}

input[type=checkbox] {
	width: 0.28rem;
	height: 0.28rem;
	margin-top: 0.02rem;
	display: inline-block;
	text-align: center;
	vertical-align: baseline;
	line-height: 0.28rem;
	position: relative;
	border-radius: 50%;
	outline: none;
	appearance: none;
	-webkit-appearance: none;
	-webkit-tab-highlight-color: rgba(0, 0, 0, 0);
	color: #000;
	background: #000;
	float: left;
}

input[type="checkbox"]::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	background-color: #000;
	width: 100%;
	height: 100%;
	color: #000;
	border-radius: 50%;
}

input[type=checkbox]:checked::before {
	content: "✓";
	font-size: 12px;
	color: #000000;
	background-color: #32FFFF;
}

.card-paction {
	font-size: 0.24rem;
	color: #8B8B8B;
	padding-bottom: 0.3rem;
	/* margin-left: 0.4rem; */
}

.card-paction span {
	color: #000;
}

/* 推广注册 */
.invite-link input {
	display: block;
	width: 70%;
	margin: 0.1rem auto;
	text-align: center;
	font-size: 0.2rem !important;
	line-height: 0.6rem !important;
	border: 1px solid #999;
	color: #999;
}

.invite-link button {
	display: block;
	margin: 0 auto;
	width: 2rem;
	height: 0.7rem;
	border-radius: 10px;
	background-color: #c2c2c2;
	font-size: 0.24rem !important;
	line-height: 0.7rem !important;
	background-color: #B8191C;
	color: #FFFFFF;
}
</style>
